/*!
 * [Typography - Table of contents]
 * 1. Base font size | 16px
 * 2. Heading 1 | 3.998em
 * 3. Heading 2 | 2.827em
 * 4. Heading 3 | 1.999em
 * 5. Heading 4 | 1.414em
 * 6. Heading 5 | 19px
 * 6. Small font size | 14px
 */
$base-fs: 16px;//0.625em;


$h1-fs: 3.998em;
$h2-fs: 2.827em;
$h3-fs: 1.999em;
$h4-fs: 1.414em;
$h5-fs: 19px;
$p-fs:  1em;
$fs-sm: 0.875em;
$fs-xs: 0.75em;
$p-lh: 26px;

html {
  font-size: $base-fs;
}

body{
  //font-size:$base-fs;
}


h1, h2, h3, h4, h5 {
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
  font-style: normal;
}

h1{
  font-size: $h1-fs;
}

h2{
  font-size: $h1-fs;
}

h3{
  font-size: $h2-fs;
}

h4{
  font-size: $h3-fs;
}

p, ul li, label, input, textarea, strong, button{
  font-size: $p-fs;
}

p {
  margin: 0;
}
/*!
 * [Pricing section - Table of contents]
 * 1. Wrapper | #prices
 * 2. Left column | .prices-left
 * 3. Right column | .prices-right
 */
$prices_h: 240px;
$prices_h-resp: auto;

div#prices {
  height: $prices_h;

  @include media-breakpoint-down(md) {
    height: $prices_h-resp;
  }

  div.prices-left {
    position: relative;
    border-right: none;
  }
  
  h3 {
    @include section-title;
  }

  &:hover .prices-left{
    background: $primary-c;

    h3 {
      color: $secondary-c;
    }
  }

  & > div {
    @include border-side-hidden(top);

    @include media-breakpoint-only(md) {
      @include elem-border-side(top);
      @include border-side-hidden(bottom);
    }
  }

  div.prices-right {


    @include elem-border-side(left);

    @media(max-width: 767px) {
      border-left: none;
      overflow: hidden;
    }

    & > div {

      text-align: center;
      z-index: 1;
      position: relative;

      p {
        position: absolute;
        bottom: $elem-pad-px;
        left: 0;
        right: 0;
        max-width: 35%;
        margin: 0 auto;
        padding-bottom: 5px;
        @include elem-underline;

        @media(max-width: 768px) and (orientation: portrait) {
          max-width: 100%;
        }

        @include media-breakpoint-down(md) {
          position: relative;
          right: initial;
          bottom: initial;
          text-align: center;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          max-width: 60%;
          bottom: 50px;
        }
      }

      h4 {
        height: calc(#{$prices_h} - 1px);
        line-height: calc(#{$prices_h} - 15px);
        font-size: $h1-fs;

        @include media-breakpoint-down(md) {
          height: auto;
          line-height: 1em;
          margin: 0 0 15px 0;
          display: block;
        }

        @include media-breakpoint-down(sm) {
          height: auto;
          line-height: 1em;
          margin: 0 0 $elem-pad-px / 2 0;
          display: block;
        }

        @media(max-width: 320px) {
          position: relative;
          left: -2px;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          position: relative;
          line-height: 1em;
          top: 75px;
        }
      }


      div.prices-wrap {
        position: relative;
        z-index: 2;
        background-color: $secondary-c;
        left: 0;
        right: 0;
        -webkit-transition: left .2s ease-in-out, right .2s ease-in-out;
        -moz-transition: left .2s ease-in-out, right .2s ease-in-out;
        -ms-transition: left .2s ease-in-out, right .2s ease-in-out;
        -o-transition: left .2s ease-in-out, right .2s ease-in-out;
        transition: left .2s ease-in-out, right .2s ease-in-out;

        span {
          color: $secondary-c;
          left: 15px;
          position: absolute;
          top: 50%;
          line-height: 30px;
          margin-top: -15px;
          font-size: 30px;

          @media(max-width: 767px) {
            display: none;
          }
        }

        &.reveal-left {
          span {
            left: initial;
            right: 15px;
          }
        }

        @media(max-width: 767px) {
          padding: 75px 0;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
          padding: 90px 0;
        }


        @media(min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
          padding: 0;
        }

      }
      //end prices-wrap

      div.prices-details {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $secondary-c;
        z-index: 1;
        padding: 45px 15px 30px;
        text-align: left;

        @media(max-width: 767px) {
          padding: 45px 15px 15px;
          text-align: center;
        }

        @media(min-width: 768px) and (max-width: 1024px) {
          padding: 15px;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          padding-top: 35px;
        }


        @media(min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
          padding-top: 35px;
        }

        h5 {
          margin-bottom: 15px;
          font-size: $h4-fs;
          display: block;
        }

        p {
          width: 59%;
          max-width: none;
          margin: 0;
          padding: 0;
          top: 0;
          bottom: 0;
          position: relative;
          display: inline-block;
          vertical-align: top;

          @media(max-width: 768px) and (orientation: portrait) {
            width: 100%;
            display: block;
            margin-bottom: 10px;
          }

          @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
            text-align: left;
          }

          @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            width: 100%;
            display: block;
            margin-bottom: 10px;
            text-align: left;
          }

          @media(min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
            width: 100%;
            display: block;
            margin-bottom: 10px;
            text-align: left;
          }

          &:after {
            display: none;
          }
        }//end p


        span {
          display: none;

          @media(max-width: 767px) {
            left: 15px;
            position: absolute;
            bottom: 15px;
            line-height: 30px;
            font-size: 30px;
            color: $primary-c;
            display: block;
          }
        }


        ul {
          display: inline-block;
          vertical-align: top;
          margin-left: 8px;
          padding-left: 7px;
          @include elem-border-side(left);
          font-size: $fs-sm;

          @media(max-width: 768px) and (orientation: portrait) {
            margin-left: 0;
            padding-left: 0;
            border: none;
          }

          @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            margin-left: 0;
            padding-left: 0;
            border: none;
          }

          @media(min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
            margin-left: 0;
            padding-left: 0;
            border: none;
          }

          li {
            margin-bottom: 5px;

            @media(max-width: 767px) {
              display: inline-block;
              width: auto;
            }

            @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
              display: inline-block;
              width: 49%;
            }

            @media(min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
              display: inline-block;
              width: auto;
            }
          }
        }


      }
      //end prices-details


      &.active {

        .prices-wrap {
          left: calc(100% + 16px);
          width: calc(100% + 30px);
          background-color: $primary-c;

          & * {
            color: $secondary-c;
          }

          &.reveal-left {
            left: initial;
            left: calc(-100% - 45px);
            width: calc(100% + 30px);
          }
        }
      }



      @include elem-border-side(right);

      &:last-child {
        @include border-side-hidden(right);
      }

      @include media-breakpoint-down(md) {
        @include border-side-hidden(right);
        @include elem-border-side(left);
      }

      @include media-breakpoint-down(sm) {
        @include elem-border-side(bottom);

        &:last-child {
          @include border-side-hidden(bottom);
        }
      }

      @include media-breakpoint-down(xs) {
        @include border-side-hidden(left);
      }

      &:hover {
        @include elem-hover(p);
      }
    }
  }//end prices-right





















}//end div#prices